<template>
  <div class="wa__model wa__model__modal mt-2">
    <v-row>
      <v-col cols="6" md="3" class="pl-3">
        <div class="d-flex align-items-center h-100 ml-4">
          <div style="line-height: 14px">
            <small class="wa__model__name--hired">HIRED BY:</small>
            <div class="wa__model__name--status">
              {{ userType === "manager" ? project.user.name : project.user.first_name }}
            </div>
            <block-button
              v-if="userType === 'manager'"
              height="10"
              :text="project.user.corporate"
              class="btn-hired"
              color="black"
            />
          </div>
        </div>
      </v-col>
      <v-col md="3" class="d-none d-md-block">
        <div class="d-flex align-center h-100">
          <div class="d-flex align-center h-100">
            <div class="timeline__button">
              <v-btn
                x-small
                color="grey-lighten-3"
                class="btn-join w-100 justify-start"
                depressed
              >
                FROM. &nbsp;{{ project.begin_date }}
              </v-btn>
              <div>
                <v-btn
                  x-small
                  color="grey-lighten-3"
                  class="btn-joi w-100 justify-startn"
                  depressed
                >
                  TO. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {{ project.end_date }}
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </v-col>
      <v-col md="3" class="text-center d-none d-md-block">
        <div class="pl-15 h-100 mt-1">
          <div class="d-flex align-center h-100">
            <div style="line-height: 12px">
              <div>
                <strong class="project-fee-price d-block text-left"
                  >${{ project.fee }}</strong
                >
              </div>
              <small class="project-fee">PROJECT FEE</small>
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="6" md="3" class="d-flex justify-content-end align-items-center">
        <div class="pr-4">
          <circle-button
            v-if="userType === 'manager'"
            icon="WMi-pencil"
            color="black"
            @click.native="$_openModal('assignProject', { model: project })"
          ></circle-button>
          <circle-button
            icon="WMi-align-left"
            color="black"
            @click.native="
              userType === 'manager'
                ? $_openModal('manager_project_detail',{model:{project}})
                : userType === 'model'
                ? $_openModal('model_project_detail',{model:{project}})
                : userType === 'client'
                ? $_openModal('client_project_detail',{model:{project}})
                : null
            "
          ></circle-button>
          <circle-button
            v-if="userType === 'manager'"
            icon="WMi-trash"
            color="red"
            bg-color="bgLightPink"
          ></circle-button>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { UserStorage } from "@/utils/storage";
export default {
  data() {
    return {
      userType: UserStorage.get() ? UserStorage.get().type : "client",
    };
  },
  props: {
    project: {
      type: Object,
    },
  },
};
</script>
<style scoped>
.project__list--title {
  width: 65%;
}

.wa__model__name--hired {
  font-size: 9px;
  color: var(--color-light-gray);
}
.wa__model__name--status {
  font-size: 15px;
  color: var(--color-black);
  text-transform: uppercase;
}
.project-fee-price {
  font-family: "Montserrat-bold";
  color: var(--color-black);
  font-size: 18px;
}
.project-fee {
  font-size: 8px;
  letter-spacing: 1px;
  padding-left: 3px;
  color: var(--color-gray);
}
.wa__model__modal {
  border: 1px solid var(--color-light-gray);
  border-radius: 4px;
  width: 100%;
  height: 55px;
  font-family: "Montserrat", sans-serif;
  margin-left: 3px;
  display: flex;
  align-items: center;
}
.btn-hired {
  font-family: "Montserrat-regular";
  font-size: 7px !important;
  letter-spacing: 0px !important;
  border-radius: 1px !important;
  cursor: default !important;
}
.btn-join {
  font-family: "Montserrat-regular";
  height: 13px !important;
  font-size: 11px !important;
  letter-spacing: 0px !important;
  border-radius: 1px !important;
  cursor: default !important;
  color: var(--color-gray) !important;
  background-color: var(--color-bg-gray) !important;
  padding: 7px 13px !important;
}
.timeline__button {
  line-height: 19px;
  margin-bottom: 3px;
}
</style>
