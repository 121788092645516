<template>
  <list
    :title="`${$_changeModelProperty(userInfo, 'name')} ${$_t('projects')}`"
    class="wa__large"
  >
    <hr class="mt-0" />
    <template>
      <v-row class="d-none d-md-flex">
        <v-col cols="3" class="wa__model__title--title"
          ><span class="pl-3">{{ $_t("name") }}</span></v-col
        >
        <v-col cols="3" class="wa__model__title--title"
          ><span class="pr-6">{{ $_t("timeLine") }}</span></v-col
        >
        <v-col cols="3" class="wa__model__title--title text-center"
          ><span class="pr-10">{{ $_t("cost") }}</span></v-col
        >
        <v-col cols="3" class="wa__model__title--title text-right"
          ><span class="pl-14">{{ $_t("controls") }}</span></v-col
        >
      </v-row>
      <data-iterator :items="getProjects" :is-filtered="isFilteredProject" @pagination="changePagination" :pagination="getPaginationProject" :loading="getProjectLoading" class="row mt-0 px-2">
            <template v-slot:loading>
                <loading />
            </template>
            <template #no-items>
              <!-- fix component -->
                <!-- <no-items :src="$_staticPath('images/global/animated/product.gif')" route-name="AddProduct" textFa=" هنوز هیچ محصولی ثبت نکرده اید! " textEn=" No Products Yet! " themeColor="product" btnText="ثبت اولین  محصول" btnIcon="plus" /> -->
                <no-items textEn=" No Products Yet! " />
            </template>
            <template #no-results>
              <!-- fix component -->
                <!-- <no-items :src="$_staticPath('images/global/animated/no-result.gif')" textFa=" متاسفانه محصولی مطابق با فیلتر های شما پیدا نکردیم! " textEn=" Sorry, No Product! " themeColor="product" btnText=" حذف فیلتر ها " /> -->
                <no-items />
            </template>

            <template v-slot:items="{item}">
                <ProjectItem :key="item.id" :project="item" />
            </template>
        </data-iterator>
    </template>
  </list>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import UserRepository from "../../abstraction/repository/userRepository";
import ProjectItem from "./Item.vue";
export default {
  components: {
    ProjectItem,
  },
  data() {
    return {
      userInfo: {},
    };
  },
  computed: {
    ...mapGetters("project", ["getPaginationProject", "getProjects","isFilteredProject","getProjectLoading"]),
  },
  methods: {
    ...mapMutations("project", ["SET_PAGINATION","SET_FILTER_PROJECT"]),
    ...mapActions("project", ["loadProjects"]),
    changePagination(pagination) {
      if (this.getPaginationProject.page !== pagination.page) {
        this.SET_PAGINATION(pagination);
        this.loadProjects();
      }
    },
    async loadUserInfo() {
      let repository = new UserRepository();
      this.userInfo = await repository.myInfo();
    },
    setFilterModelId(){
      const modelId = this.$route.params.model
        if(modelId){
          this.SET_FILTER_PROJECT({model_id:{val:modelId,type:"in"}});
        }
    }
  },
  created() {
    this.setFilterModelId();
    this.loadProjects();
    this.loadUserInfo();
  },
};
</script>
<style scoped>
.wa__model__title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  height: 16px;
}
.wa__model__title--title {
  font-family: "Montserrat";
  font-size: 0.6rem;
  color: var(--color-light-gray);
  text-transform: uppercase;
  letter-spacing: 2px;
}
.wa__add__new__project {
  text-align: right;
  right: 50px;
  bottom: 20px;
}

</style>
