<template>
  <basic-modal @open="openModal" width="800" transition="slide-x-transition">
    <template #header>
      <divider title="ASSIGN PROJECT" sub-title="LETS DO BUSINESS" size="small"></divider>
    </template>
    <template #default>
      <v-row class="px-12 pt-3 ma-0">
        <v-col cols="6" class="pt-0 pb-0">
          <v-autocomplete
            v-model="form.request"
            :items="requests"
            :loading="isLoading"
            @keyup="search"
            color="SonicSilver"
            hide-no-data
            hide-selected
            item-text="user.name"
            item-value="id"
            label="REQUESTER-SEARCH"
            append-icon="WMi-search"
            :disabled="!!form.id"
            dense
            outlined
            return-object
          ></v-autocomplete>
        </v-col>
        <v-col cols="6" />
        <v-col cols="6" class="pt-0 pb-0">
          <v-text-field
            color="SonicSilver"
            dense
            outlined
            label="PROJECT NAME"
            class="pt-0"
            v-model="form.name"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="pt-0 pb-0">
          <v-textarea
            rows="4"
            color="SonicSilver"
            dense
            outlined
            label="project description"
            class="pt-0"
            v-model="form.description"
          ></v-textarea>
        </v-col>
        <v-col cols="5" class="pt-0 pb-0">
          <date-input
            color="SonicSilver"
            dense
            outlined
            label="DATE, BEGIN"
            class="pt-0"
            v-model="form.begin_date"
            :min="today"
            :max="form.end_date"
          ></date-input>
        </v-col>
        <v-col cols="5" class="pt-0 pb-0">
          <date-input
            color="SonicSilver"
            dense
            outlined
            label="DATE, END"
            class="pt-0"
            v-model="form.end_date"
            :min="form.begin_date || today"
          ></date-input>
        </v-col>
        <v-col cols="2" />
        <v-col cols="5" class="pt-0 pb-0">
          <v-text-field
            label="PROJECT FEE"
            color="SonicSilver"
            dense
            outlined
            class="pt-0"
            v-model="form.fee"
            append-icon="WMi-money-check-alt"
          ></v-text-field>
        </v-col>
      </v-row>
    </template>
    <template #footer>
      <v-spacer></v-spacer>
      <div class="px-6 py-2 d-flex justify-space-between align-items-center w-100">
        <block-button
          class="btn-cancel btn__size__18"
          text="CANCEL"
          icon="WMi-cancel-linear"
          text-mode="text"
          @click.native="$_closeModal()"
        ></block-button>
        <block-button
          height="30"
          class="btn-assign ml-5 site__button width-auto btn__size__16"
          text="ASSIGN THE PROJECT TO MODEL"
          icon="WMi-plus-linear"
          :loading="requestLoading"
          @click.native="addProject"
        ></block-button>
      </div>
    </template>
  </basic-modal>
</template>
<script>
import { mapActions } from "vuex";
import RequestRepository from "@/abstraction/repository/requestRepository";
import debouncedFunction from "@/utils/filter/autocompleteFilter.js";
export default {
  name: "modal_assignProject",
  data() {
    return {
      isLoading: false,
      requestLoading: false,
      model: null,
      requests: [],
      form: {},
      today: new Date().toISOString().slice(0, 10),
    };
  },
  methods: {
    openModal({ model }) {
      if (model) {
        this.form = { ...model };
        if (this.form.id) {
          this.loadProjectSelected()
        }
      }
    },
    async loadProjectSelected() {
      const params = {
        filters : {
          model_id: { type: "in", val: this.form.model.id },
          user_name: { type: "like", val: this.form.user.name },
        }
      }
      this.getProjectFromServer(params)
    },
    ...mapActions("project", ["storeProject", "updateProject"]),
    async addProject() {
      try {
        this.requestLoading = true;
        if (this.form.id) {
          await this.updateProject(this.form);
        } else {
          await this.storeProject(this.form);
        }
        this.$_closeModal();
      } catch (e) {
        return e;
      } finally {
        this.requestLoading = false;
      }
    },
    async getProjectFromServer(params) {
        try {
          let repository = new RequestRepository();
          let response = await repository.index(params);
          if (response) {
            this.requests = response.data;
          }
        } catch(e){
          return e;
        }
    },
    async search(event) {
    debouncedFunction(event, async () => {
      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;

      // Lazily load input items
      try {
          const params = {
          filters: {
            model_id: { type: "in", val: this.form.model_id },
            user_name: { type: "like", val:event.target.value },
        }
          }
          this.getProjectFromServer(params);
      } finally {
        this.isLoading = false;
      }
      });
    },
  },
};
</script>
<style scoped>
.btn-assign {
  padding: 20px 35px !important;
  font-family: "Montserrat-ExtraLight";
}
</style>
