<template>
  <v-form @submit.prevent="submit" class="h-100">
    <list :title="$_t('filters')" :filter="true" class="h-100">
      <hr class="mt-0" />
      <v-row>
        <v-col cols="12" class="pt-4" v-if="userType !== 'model'">
          <div class="h-40">
            <v-text-field
              :label="$_t('modelName')"
              color="SonicSilver"
              v-model="filter.model_name.val"
              outlined
              dense
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="12" v-if="userType !== 'model'">
          <div class="h-40">
            <v-text-field
              :label="$_t('userName')"
              color="SonicSilver"
              v-model="filter.user_name.val"
              outlined
              dense
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="12">
          <div class="h-40">
            <date-input
              :label="$_t('dateAfter')"
              color="SonicSilver"
              v-model="filter.created_at.val1"
              outlined
              dense
            ></date-input>
          </div>
        </v-col>
        <v-col cols="12">
          <div class="h-40">
            <date-input
              :label="$_t('dateBefore')"
              color="SonicSilver"
              v-model="filter.created_at.val2"
              outlined
              dense
            ></date-input>
          </div>
        </v-col>
      </v-row>
      <v-row class="mb-3">
        <v-col cols="4" class="pr-0">
          <block-button
            height="30"
            class="w-100 cancel__button br__mode btn__size__14"
            :text="$_t('clear')"
            color="black"
            @click.native="clearFilter"
            outlined
            dense
          ></block-button>
        </v-col>
        <v-col cols="8 pl-1">
          <block-button
            height="30"
            class="w-100 site__button width-auto add__mode btn__size__18"
            :text="$_t('execute')"
            type="submit"
          ></block-button>
        </v-col>
      </v-row>
    </list>
  </v-form>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { cloneDeep } from "lodash";
import { UserStorage } from "@/utils/storage";
const defaultFilter = {
  created_at: {
    type: "between",
    val1: null,
    val2: null,
  },
  model_name: {
    type: "like",
    val: null,
  },
  user_name: {
    type: "like",
    val: null,
  },
  model_id :{ 
    type : "in",
    val : null,
  }
};
export default {
  data() {
    return {
      userType: UserStorage.get() ? UserStorage.get().type : null,
      filter: {},
      type: UserStorage.get().type,
    };
  },
  computed: {
    ...mapGetters("status", ["getStatuses"]),
    ...mapGetters("project", ["getFiltersProject"]),
  },
  methods: {
    ...mapActions("status", ["loadStatuses"]),
    ...mapMutations("project", [
      "SET_FILTER_PROJECT",
      "SET_IS_FILTERED_PROJECT",
    ]),
    ...mapActions("project", ["loadProjects"]),
    submit() {
      this.SET_IS_FILTERED_PROJECT(true);
      this.SET_FILTER_PROJECT(this.filter);
      this.loadProjects();
    },
    clearFilter() {
      this.SET_IS_FILTERED_PROJECT(false);
      this.filter = cloneDeep(defaultFilter);
      this.SET_FILTER_PROJECT(cloneDeep(this.filter));
      this.loadProjects();
    },
    loadPastFilter() {
      this.filter = cloneDeep({ ...defaultFilter, ...this.getFiltersProject });
    },
  },
  beforeCreate(){
    const modelId = this.$route.params.model
    defaultFilter.model_id.val =  modelId ? modelId : null;
  },
  created() {
    this.loadStatuses();
    this.loadPastFilter();
  },
};
</script>
<style scoped>
.h-40 {
  height: 40px;
}
</style>
