<template>
  <basic-modal width="1300" @open="openModal" transition="slide-x-transition">
    <template #header>
      <divider
        title="project DETAILS"
        sub-title="the details you need"
        size="small"
      ></divider>
    </template>
    <template #default>
      <div class="px-6 px-md-14 mt-8 mb-11">
        <div>
          <SectionTitle title="project data" />
          <div class="mt-2">
            <v-row>
              <v-col cols="3">
                <RequestDetailsInformation
                  class="mr-0"
                  title="project name"
                  :subTitle="project.name"
                />
              </v-col>
              <v-col cols="3">
                <RequestDetailsInformation
                  class="mr-0"
                  title="date, begin"
                  :subTitle="project.begin_date"
                />
              </v-col>
              <v-col cols="3">
                <RequestDetailsInformation
                  class="mr-0"
                  title="date, end"
                  :subTitle="project.end_date"
                />
              </v-col>
              <v-col cols="3">
                <RequestDetailsInformation
                  class="mr-0"
                  title="project fee"
                  :subTitle="'$'+project.fee"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <RequestDetailsInformation
                  class="mr-0"
                  title="project description"
                  :subTitle="project.description"
                />
              </v-col>
            </v-row>
          </div>
        </div>
        <div class="mt-13 pb-6">
          <SectionTitle title="requester" />
          <div class="mt-2">
            <v-row>
              <v-col cols="2">
                <RequestDetailsInformation
                  class="mr-0"
                  title="Full name"
                  :subTitle="project.user.name"
                />
              </v-col>
              <v-col cols="4">
                <RequestDetailsInformation
                  class="mr-0"
                  title="EMAIL"
                  :subTitle="project.user.email"
                />
              </v-col>
              <v-col cols="2">
                <RequestDetailsInformation
                  class="mr-0"
                  title="Corporate"
                  :subTitle="project.user.corporate"
                />
              </v-col>
              <v-col cols="2">
                <RequestDetailsInformation
                  class="mr-0"
                  title="Total Requests"
                  :subTitle="project.user.userRequestsCount"
                />
              </v-col>
              <v-col cols="2">
                <div class="mt-1 more float-right">
                  <block-button
                    text="KNOW MORE"
                    icon-right="WMi-right-open"
                    class="btn-more site__button width-auto"
                    @click.native="$_openModal('clientDetails',{model:{...project.user}})"
                  ></block-button>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
        <div class="mt-13 pb-16">
          <SectionTitle title="model" />
          <div class="mt-2">
            <v-row>
              <v-col cols="3">
                <RequestDetailsInformation
                  class="mr-0"
                  title="Full name"
                  :subTitle="project.model.name"
                />
              </v-col>
              <v-col cols="2">
                <RequestDetailsInformation
                  class="mr-0"
                  title="gender"
                  :subTitle="project.model.gender.name"
                />
              </v-col>
              <v-col cols="2">
                <RequestDetailsInformation
                  class="mr-0"
                  title="Total jobs"
                  :subTitle="project.model.modelProjectsCount"
                />
              </v-col>
              <v-col cols="2">
                <RequestDetailsInformation
                  class="mr-0"
                  title="Phone number"
                  :subTitle="project.model.cell_phone"
                />
              </v-col>
              <v-col cols="3">
                <div class="mt-1 more float-right">
                  <router-link
                    class="text-decoration-none"
                    :to="{
                      name: 'modelDetailProject',
                      params: { 
                         id: project.model.id, locale: $_getlocaleParam() ,
                      },
                    }"
                  >
                    <block-button
                      text="KNOW MORE"
                      icon-right="WMi-right-open"
                      class="btn-more site__button width-auto"
                      @click.native="$_closeModal()"
                    ></block-button>
                  </router-link>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <v-spacer></v-spacer>
      <div class="px-3 pt-2 pb-2">
        <block-button
          class="btn-allright btn__modal--assign mr-5 site__button width-auto btn__size__20"
          text="IT'S ALLRIGHT, THANK YOU"
          @click.native="$_closeModal()"
          height="39"
        ></block-button>
      </div>
    </template>
  </basic-modal>
</template>
<script>
import SectionTitle from "@/components/Global/Section/SectionTitle.vue";
import RequestDetailsInformation from "@/components/Global/Section/RequestDetailsInformation.vue";
export default {
  name: "modal_manager_project_detail",
  components: {
    SectionTitle,
    RequestDetailsInformation,
  },
  data() {
    return {
      project: {},
    };
  },
  methods: {
    openModal({model}){
      if(model){
        this.project= {...model.project};
      }
    }
  }
};
</script>
<style scoped>
.btn__modal--assign {
  padding: 20px 35px !important;
  font-family: "Montserrat-ExtraLight";
}
.btn-more {
  font-family: "Barlow-EL", sans-serif;
  padding: 13px 10px !important;
}
.request__details__information::before {
  height: 24px;
  background-color: var(--color-light-gray);
  content: "";
  display: block;
  width: 1px;
  margin: 7px 2px 0 0;
}
.request__details__information--title {
  color: var(--color-smoky);
  font-size: 12px;
  font-family: "Montserrat-ExtraLight";
  text-transform: uppercase;
}
.request__details__information--sub-title {
  font-size: 15px;
  font-family: "Montserrat";
  color: var(--color-black);
  position: relative;
  top: -0.3rem;
}
.category__chip {
  font-size: 14px;
  font-family: "Montserrat-light";
}
</style>
