<template>
  <div class="wa__container">
    <header class="pt-3">
      <div class="pt-3">
        <v-row>
          <v-col cols="12">
            <site-header
              backRouteName="dashboard"
              :backText="$_t('backText')"
              :title="$_t('title')"
              :sub-title="$_t('subTitle')"
            ></site-header>
          </v-col>
        </v-row>
      </div>
    </header>
    <main>
      <div>
        <v-row class="justify-content-center pb-4 wa__projects">
          <v-col cols="12" lg="2">
            <ProjectFilter />
          </v-col>
          <v-col cols="12" lg="10">
            <ProjectItems />
          </v-col>
        </v-row>
      </div>
    </main>
    <footer>
      <div class="wa__home--footer">
        <site-footer :text="$_t('footerText')"></site-footer>
      </div>
    </footer>
    <div class="text-center">
      <ModalAssignProject v-if="isModal('modal_assignProject')" />
      <!-- MANAGER PROJECT DETAIL -->
      <ModalManagerProjectDetail
        v-if="isModal('modal_manager_project_detail') && type === 'manager'"
      />
      <!-- MODEL PROJECT DETAIL -->
      <ModalModelProjectDetail
        v-if="isModal('modal_model_project_detail') && type === 'model'"
      />
      <!-- CLIENT PROJECT DETAIL -->
      <ModalClientProjectDetail
        v-if="isModal('modal_client_project_detail') && type === 'client'"
      />
      <ModalClientDetail v-if="isModal('modal_clientDetails')" />
    </div>
  </div>
</template>
<script>
import { mapGetters,mapMutations } from "vuex";
import ProjectFilter from "../components/Project/Filter";
import ProjectItems from "../components/Project/Items";
import ModalAssignProject from "@/components/Project/Modal/ModalAssign";
import ModalClientProjectDetail from "@/components/Project/Modal/ClientProjectDetail";
import ModalManagerProjectDetail from "@/components/Project/Modal/ManagerProjectDetail";
import ModalModelProjectDetail from "@/components/Project/Modal/ModelProjectDetail";
import ModalClientDetail from "@/components/Request/ModalClient";
import { UserStorage } from "@/utils/storage";

export default {
  components: {
    ProjectFilter,
    ProjectItems,
    ModalAssignProject,
    ModalClientProjectDetail,
    ModalManagerProjectDetail,
    ModalModelProjectDetail,
    ModalClientDetail,
  },
  data: () => ({
    type: UserStorage.get().type,
  }),
  computed: {
    ...mapGetters("modal", ["isModal"]),
  },
  methods: {
    ...mapMutations('project', ['DESTROY_HISTORY']),
  },
  destroyed(){
    this.DESTROY_HISTORY()
  }
};
</script>
